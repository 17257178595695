import axios from 'axios'
import store from '../../index'

export default {

    async getAdvisorFiles({commit}, category) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/advisor/${category}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async deleteAdvisorFile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/advisor/${data.id}`, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getAdvisorFile({commit}, slug) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/files/${slug}`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async uploadAdvisorFile({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/dashboard/advisor`, data: data.data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
