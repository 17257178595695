import axios from 'axios'

export default {
    // Login User
    async adminLogin({commit}, data) {
        return new Promise((resolve, reject) => {
            // commit('auth_request')
            axios({url: '/admin/login', data: data, method: 'POST'})
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data
                    localStorage.setItem('token', token)
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                    commit('auth_success', user)
                    resolve(resp.data)
                })
                .catch(err => {
                    commit('auth_error')
                    reject(err)
                })
        })
    },

    async adminLoginAsUser({commit}, data) {

        return new Promise((resolve, reject) => {
            // commit('auth_request')
            axios({url: '/admin/login-as-user', data: data, method: 'POST'})
                .then(resp => {
                    if (!resp.action) {
                        const token = resp.data.token
                        const user = resp.data
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                        commit('auth_success', user)
                    }
                    resolve(resp.data)
                })
                .catch(err => {
                    commit('auth_error')
                    reject(err)
                })
        })
    },

    // Logout Admin
    async adminLogout({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: '/admin/logout', method: 'POST'})
                .then(resp => {
                    commit('logout')
                    localStorage.removeItem('token')
                    delete axios.defaults.headers.common['Authorization']
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
