<template>
  <v-row justify="center">
    <v-dialog
        :value="value"
        @input="$emit('input', $event)"
        persistent
        max-width="700px"
    >
      <v-card>
        <v-card-title>
          <span v-if="pModel">Mitgliedschaft buchen</span>
          <span v-else>{{ $t('register') }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="$emit('close-register-modal'); pModel = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="auth-form register" id="register-dialog">
            <div v-if="!$store.getters.isLoggedIn">
              <v-row v-if="pModel && pModel.price !== 0">
                <v-col>
                  <div class="font-weight-bold">Deine gewählte Mitgliedschaft: <span class="font-italic">{{ pModel.title }} {{ pModel.price }}€/{{ pModel.period }}</span></div>
                  <div class="mt-3">
                    Nach der Registrierung wirst Du zu unserem Shop weitergeleitet.<br>
                    Bei erfolgreicher Bezahlung erhältst Du einen Freischaltcode.
                    Nach dem Du deinen Account erfolgreich bestätigt hast, kannst Du dich einloggen und den Code eingeben um Deine Mitgliedschaft freizuschalten.
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col>
                  Nach der Registrierung erhältst Du eine E-Mail mit einem Bestätigungslink.<br>
                  Nach dem Du dein Account erfolgreich bestätigt hast, kannst Du Dich einloggen und die kostenlosen Funktionen von Geras24 nutzen.
                </v-col>
              </v-row>
              <v-row class="mt-4">
                <v-text-field
                    :label="$t('first_name')"
                    :disabled="loading"
                    filled
                    rounded
                    :name="Math.random()"
                    v-model="form.firstname"
                ></v-text-field>
              </v-row>
              <v-row class="mt-0">
                <v-text-field
                    :label="$t('last_name')"
                    :disabled="loading"
                    filled
                    rounded
                    :name="Math.random()"
                    v-model="form.name"
                ></v-text-field>
              </v-row>
              <v-row class="mt-0">
                <v-text-field
                    email
                    :label="$t('email')"
                    :disabled="loading"
                    filled
                    rounded
                    :name="Math.random()"
                    v-model="form.email"
                ></v-text-field>
              </v-row>
              <v-row class="mt-0">
                <v-text-field
                    :label="$t('password')"
                    type="password"
                    :disabled="loading"
                    filled
                    rounded
                    :name="Math.random()"
                    v-model="form.password"
                ></v-text-field>
              </v-row>
              <v-row class="mt-0">
                <v-text-field
                    :label="$t('password_confirm')"
                    type="password"
                    :disabled="loading"
                    filled
                    rounded
                    :name="Math.random()"
                    v-model="form.password_confirmation"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="form.terms" class="register">
                    <template v-slot:label>
                      <div v-if="pModel">
                        Ich akzeptiere die
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                                target="_blank"
                                class="dark"
                                href="/allgemeine-verkaufsbedingungen"
                                @click.stop
                                v-on="on"
                            >
                              Verkaufsbedingungen</a>
                          </template>
                          Zu den Verkaufsbedingungen
                        </v-tooltip>
                        und habe die
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                                target="_blank"
                                class="dark"
                                href="/datenschutz"
                                @click.stop
                                v-on="on"
                            >
                              Datenschutzbestimmungen</a>
                          </template>
                          Zu den Datenschutzbestimmungen
                        </v-tooltip>
                        zur Kenntnis genommen
                      </div>
                      <div v-else>
                        Ich habe die
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <a
                                target="_blank"
                                class="dark"
                                href="/datenschutz"
                                @click.stop
                                v-on="on"
                            >
                              Datenschutzbestimmungen</a>
                          </template>
                          Zu den Datenschutzbestimmungen
                        </v-tooltip>
                        zur Kenntnis genommen
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row class="mt-2" justify="center">
                <v-btn
                    elevation="5"
                    :loading="loading"
                    :disabled="loading"
                    color="turquoise"
                    block
                    @click="register()"
                >
                  <span v-if="!pModel">Registrieren</span><span v-else>Registrieren und weiter zum Shop</span>
                </v-btn>
              </v-row>
              <v-row class="pt-2" v-if="!pModel">
                <v-col justify="center" align="center">
                  <p><a href="#" @click="closeAndOpenLoginModal()" class="primary--text">{{ $t('login') }}</a> {{ $t('with_your_account') }}</p>
                </v-col>
              </v-row>
              <v-row v-else class="pt-6">
              </v-row>
            </div>
            <div v-else>
              <v-row v-if="pModel && pModel.price !== 0">
                <v-col>
                  <div class="font-weight-bold">Deine gewählte Mitgliedschaft: <span class="font-italic">{{ pModel.title }} {{ pModel.price }}€/{{ pModel.period }}</span></div>
                  <div class="mt-3">
                    Buche bitte deine Mitgliedschaft in unserem Shop.<br>
                    Bei erfolgreicher Bezahlung erhältst du einen Freischaltcode den du in deinem Account einlösen kannst.
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-8 mb-5" justify="center">
                <v-btn
                    elevation="5"
                    :loading="loading"
                    :disabled="loading"
                    color="turquoise"
                    block
                    @click="redirectToShop"
                >
                  Zum Shop
                </v-btn>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from "vue";

export default {
  props: ['value', 'priceModel'],
  data() {
    return {
      loading: false,
      form: {
        firstname: null,
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        terms: null,
        referred_by: this.$route.query.c || null
      },
      pModel: null
    }
  },
  watch: {
    'priceModel': function () {
      this.pModel = this.priceModel
    }
  },
  methods: {
    register() {
      if (!this.form.firstname) {
        Vue.$toast.error(this.$t('enter_firstname'))
        return
      }
      if (!this.form.name) {
        Vue.$toast.error(this.$t('enter_name'))
        return
      }
      if (!this.form.email) {
        Vue.$toast.error(this.$t('enter_email'))
        return
      }
      if (!this.form.password) {
        Vue.$toast.error(this.$t('enter_password'))
        return
      }
      if (!this.form.password_confirmation) {
        Vue.$toast.error(this.$t('enter_password_confirm'))
        return
      }
      if (!this.form.terms) {
        Vue.$toast.error(this.$t('accept_terms'))
        return
      }
      if (this.form.password != this.form.password_confirmation) {
        Vue.$toast.error(this.$t('password_confirm_error'))
        return
      }

      this.loading = true

      this.$store.dispatch('register', this.form)
          .then(() => {
            this.$emit('close-register-modal')
            Vue.$toast.success(this.$t('register_send_activation_email'))
            this.form = {}

            if (this.priceModel) {
              this.redirectToShop()
            } else {
              if (typeof fbq !== "undefined") {
                window.fbq('track', 'Lead')
              }

              this.loading = false
            }
          })
          .catch(err => {
            this.$helpers.showError(err)
            this.loading = false
          })
    },
    redirectToShop() {
      this.loading = true;

      setTimeout(() => {
        let redirect = window.open(this.priceModel.shopUrl, '_blank');

        if (!redirect || redirect.closed || typeof redirect.closed == 'undefined') {
          this.$emit('open-popup-blocked-modal')
        }

        this.loading = false;
      }, 1500)
    },
    closeAndOpenLoginModal() {
      this.$emit('close-register-modal')
      this.$emit('open-login-modal')
    },
  }
}
</script>
