export default {
    login: "Login",
    username_or_email: "E-Mail-Adresse",
    email: "E-Mail-Adresse",
    password: "Passwort",
    safety: "Sicherheit",
    google: "Google",
    facebook: "Facebook",
    create_account: "Registrieren",
    no_account: "Noch kein Account? ",
    create_account_for_free: "Kostenlos registrieren",
    for_personal_or_business: "für privat oder geschäftlich",
    forget_password: "Passwort vergessen?",
    success_verify: "Ihr Account wurde erfolgreich verifiziert! Sie können sich jetzt einloggen!",
    success_login: "Willkommen bei Geras24!",
    enter_username: "Bitte geben Sie den Benutzernamen oder Ihre Email ein!",
    enter_password: "Bitte geben Sie Ihr Passwort ein!",
    redirect_to_provider: "Weiterleitung zur Anbieterseite ...",
    register: "Registrieren",
    name: "Name",
    first_name: "Vorname",
    last_name: "Nachname",
    username: "Benutzername",
    password_confirm: "Passwort bestätigen",
    referral_code: "Referenzcode",
    with_your_account: "mit Ihrem Konto",
    enter_firstname: "Bitte geben Sie Ihren Vornamen ein!",
    enter_name: "Bitte geben Sie Ihren Nachnamen ein!",
    enter_email: "Bitte geben Sie Ihre Email ein!",
    enter_password_confirm: "Bitte Passwort bestätigen!",
    accept_terms: "Bitte Datenschutz bestätigen!",
    password_confirm_error: "Passwort und Bestätigung stimmen nicht überein!",
    register_send_activation_email: "Bitte klicken Sie auf den Aktivierungscode den wir Ihnen per Mail gesendet haben!",
    recover_password: "Password wiederherstellen",
    new_password: "neues Passwort",
    confirm_new_password: "Bestätigung neues Passwort",
    password_hint: "Mindestens 8 Zeichen",
    recover_send_reset_email: "Bitte klicken Sie auf den Wiederherstellungslink den wir an Ihre Email gesendet haben!",
    password_changed: "Neues Passwort erfolgreich geändert!",
    user_not_verified: "Benutzer nicht verifiziert!",

    checklist_title: "Ihre persönliche Checkliste",
    checklist_subtitle: "Wenn ein Familienmitglied stirbt, müssen trotz der Trauer viele bürokratische Angelegenheiten geklärt werden. In dieser emotionalen Ausnahmesituation droht die Bürokratie uns zu überfordern.",
    checklist_checkliste: "Checkliste",
    checklist_meldepflichtig: "Meldepflichtig",
    checklist_frist_nach_todesfall: "Frist nach Todesfall",
    checklist_besonderheiten: "BESONDERHEITEN",
    checklist_notizen: "Notizen",
    checklist_versicherungen: "Versicherungen",
    checklist_mitgliedschaften: "Mitgliedschaften",

    collections_botschaften_title: "Persönliche Botschaften",
    collections_botschaften_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",
    collections_wunsch_title: "Der letzte Wunsch",
    collections_wunsch_subtitle: "Hinterlassen Sie Ihre persönlichen Botschaften in Form von Videos, Bildern, Briefen, oder Sprachnachrichten.",
    collections_select_panel: "Plan auswählen:",

    cancel: "Abbrechen",
    save: "speichern",
    photos: "Fotos",
    documents: "Briefe",
    videos: "Videos",
    audios: "Sprachnachrichten",
    create_new_category: "Erste Kategorie erstellen",
    play: "Play",
    description: "Beschreibung",
    size: "Größe",
    date: "Datum",
    actions: "Aktionen",

    upload_new_file: "Laden Sie hier weitere Dokumente hoch",
    are_you_sure: "Sind Sie sicher?",
    delete_file_alert: "Nach dem Löschen ist die Datei unwiederbringlich verloren.",
    delete_category_alert: "Durch das Löschen der Kategorie werden alle darin enthaltenen Dateien gelöscht und können nicht mehr wiederhergestellt werden",
    category_name: "Kategorie Name",
    yes: "Ja",
    no: "Nein",
    later: "Später",
    buy_now: "Zu den Mitgliedschaften",
    buy: "Kaufen",
    need_payment: "Keine Mitgliedschaft vorhanden",
    need_payment_desc: "Um auf diesen Bereich zugreifen zu können, müssen Sie eine Mitgliedschaft abschließen.",

    upload_new_media_subtitle: "Dateiformate: JPG, JPEG, PNG, BMP, GIF, SVG, AVI, MP4, MKV, FLV, WEBM, MOV, WMV, MP3, WAV, M4A, OGG",
    upload_new_document_subtitle: "Dateiformate: TXT, DOC, DOCX, PDF, ZIP, XLSX, PPT, PPTX",
    uploading: "Uploading ... bitte warten",
    upload: "Upload",
    dont_close_page: "Bitte die Seite nicht schliessen",
    file: "Datei",
    new_file: "Neue Datei",
    select_file: "Bitte Dateien wählen",
    file_desc: "Datei Bezeichnung",
    media_type_alert: "Die Datei muss der Typ der entsprechenden Mediadateien sein",
    file_stored: "Neue Datei gespeichert!",

    logout: "Abmelden",
    logout_admin: "Zurück zum Admin",
    logout_trusted_user: "Zurück zu meinem Konto",

    dashboard: "Dashboard",
    botschaften: "Persönliche Botschaften",
    checkliste: "Checkliste",
    tresor: "Tresor",
    trusted_menu: "Vertrauenspersonen",
    up: "Up",
    up_subtitle: "Dieser Bereich ist in Kürze für Sie verfügbar. Wir werden Sie über Neuigkeiten informieren.",
    support: "Support",
    support_tickets: "Support Tickets",
    profile: "Profil",
    wunsch: "Der letzte Wunsch",
    pending: 'Ausstehend',
    open: "Offen",
    closed: "Geschlossen",
    close: "Schließen",
    comments: "Bemerkungen",
    accepted: "Akzeptiert",
    rejected: "abgelehnt",
    pays: "Zahlungen",
    all: "alle",
    successful: "erfolgreich",
    amount: "Betrag",

    information: "Persönliche Daten",
    personal_information: "Persönliche Informationen",
    keys: "Schlüssel",
    account: "Zahlung",
    trusters: "Vertrauter",
    truster: "Vertrauter",
    notifications: "Benachrichtigungen",
    tap_to_edit: "Klicken um zu ändern",
    status: 'Status',
    plan_subscription: 'Mitgliedschaft',
    subscription_users: 'Abonnement-Nutzer',

    age: "Alter",
    martial_status: "Familienstand",
    verheiratet: "Verheiratet",
    single: "Ledig",
    indent_nr: "Rentenversicherungsnummer",
    address: "Adresse",
    street: "Strasse",
    house_number: "Hausnummer",
    zip: "PLZ",
    city: "Stadt",
    height: "Größe",
    spouse: "Partner",
    job: "Beruf",
    birthplace: "Geburtsort",
    gender: "Geschlecht",
    children: "Kinder",
    birthdate: "Geburtstag",
    nationality: "Nationalität",
    national_code: "Ausweis-Nr.",
    phone: "Telefon",

    keys_title: "Sicherheitsschlüssel für Verschlüsselung und Entschlüsselung",
    keys_subtitle1: "Mit den folgenden Schlüsselpaaren können Sie Ihre Dateien sicher ver- und entschlüsseln",
    keys_subtitle2: "Verschlüsselte Dateien können nur mit diesen Schlüsseln wiederhergestellt werden.",
    keys_subtitle3: "Wenn die Schlüssel verloren gehen, gehen alle damit verschlüsselten Dateien verloren.",
    keys_subtitle4: "Der private Schlüssel wird nur einmal erstellt, also seien Sie sorgfältig bei der Aufbewahrung.",
    public_key: "Öffentlicher Schlüssel",
    private_key: "Privater Schlüssel",
    generate_key_pairs: "Neue Schlüsselpaare generieren",
    revoke_keys: "Schlüssel aufheben",
    download_keys: "Herunterladen",
    please_wait: "Bitte warten ...",
    choose_prv_key: "Bitte wählen Sie Ihren privaten Schlüssel (.pem file) für Entschlüsselungsprozesse.",
    prv_pem_file: "Privater Schlüssel Pem Datei",

    prv_dont_access: "Aus Datenschutz- und Sicherheitsgründen haben wir keinen Zugriff auf Ihren Schlüssel. Achten Sie daher darauf, Ihren privaten Schlüssel aufzubewahren. Wir können Ihre Daten nicht wiederherstellen, wenn Sie Ihren Schlüssel verlieren  ",
    downloading: "Downloading, bitte warten!",
    key_updated: "Schlüssel aktualisiert!",
    notifications_subtitle: "In diesem Abschnitt werden alle Ihre Nachrichten und Aktivitäten angezeigt.",
    title: "Titel",
    message: "Nachricht",
    empty_notifications: "Derzeit ist keine Nachricht für Sie vorhanden",
    account_info: "Kontoübersicht",
    current_password: "aktives Passwort",

    pay_verify: "Zahlung überprüfen",
    pay_success: "Ihre Zahlung war erfolgreich. Ihr Abonnement ist aktualisiert worden",
    pay_error: "Ihre Zahlung ist fehlgeschlagen. Sie können zum Abschnitt Abonnements zurückkehren und Ihren Vorgang wiederholen.",
    plans: "Zahlungspläne",
    remaining_days: "Tage übrig",
    days: "Tage",
    storage: "Speicherplatz",
    task_limit: "Aufgaben Limit",
    trusted_limit: "Limit an Vertrauenspersonen",
    duration: "Dauer",
    price: "Preis",
    redeem_code_success: "Ihr Abo wurde erfolgreich aktiviert",
    redeem_code_error: "Ihr Abo konnte nicht aktiviert werden",

    new_ticket: "Neues Ticket",
    new_ticket_subtitle: "Bitte schreiben Sie Ihre Nachricht und senden Sie diese an die zuständige Abteilung",
    loading: "Lädt… bitte warten",
    empty_data: "Keine Support-Tickets vorhanden",
    department: "Abteilung",
    subject: "Betreff",
    last_update: "letzte Änderung",
    error: "Fehler",
    send: "senden",
    ticket: "Ticket",
    created_at: "Erstellt am",
    new_message: "Neue Nachricht",
    tresor_subtitle: "Ein sicherer Ort für Ihre Dateien, fortschrittliche Verschlüsselung, die nur Sie und Ihre Treuhänder öffnen können",

    no_keys: "Sicherheitsschlüssel nicht gefunden!",
    no_keys_subtitle: "Um auf diesen Abschnitt zuzugreifen, müssen Sie zuerst die Sicherheitsschlüssel für Ihr Konto erstellen.",
    create_now: "Jetzt erstellen!",
    file_upload_success: "Datei erfolgreich hochgeladen!",

    my_trusted_title: "Meine Vertrauten",
    my_trusted_desc: 'In diesem Abschnitt können Sie Personen eintragen, denen Sie vertrauen. Diese Personen können dadurch jederzeit auf Ihr Profil zugreifen z. B. in einer Notfallsituation.',
    entrusted_to_me_title: "Mir Anvertraute",
    entrusted_to_me_desc: 'In diesem Abschnitt können Sie sehen, wer Ihnen vertraut und Ihnen Zugriff auf deren Profil gewährt hat. Sie können die folgende ID an Ihre Vertrauten weitergeben, um ihnen den Zugriff zu ermöglichen:',
    no_trusted_users: 'Bisher wurden noch keine Vertrauenspersonen angelegt.',
    no_entrusted_users: 'Bisher wurde Ihnen noch kein Zugriff auf ein Profil gewährt.',
    add_to_trusted: "Hinzufügen",
    trusted_added_success: "Vertrauensperson erfolgreich hinzugefügt.",
    trusted_removed_success: "Vertrauensperson erfolgreich entfernt.",
    trusted_add_already_registered: "Bereits registrierte Person hinzufügen",
    trusted_add_unregistered: "Noch nicht registrierte Person hinzufügen",
    trusted_user_id_place_description: "Bitte geben Sie die ID der Person ein, der Sie vertrauen möchten. Die Person findet die ID in ihrem Profil unter Vertrauenspersonen > Mir Anvertraute.",
    trusted_user_id: "Vertrauensperson-ID",
    trusted_user_id_copied: "Vertrauensperson-ID kopiert!",
    trusted_user_invited: "Eingeladen",
    trusted_user_entrusted: "Zugriff erteilt",

    users: "Benutzer",
    add: "Hinzufügen",
    delete_trusted_alert: "Vertrauensperson wirklich entfernen?",
    resend_email_alert: "Soll die E-Mail an die Vertrauensperson erneut versendet werden?",
    truster_email_resent: "E-Mail an die Vertrauensperson wurde versendet",
    name_username: "Name oder Benutzername",
    start_type_to_search: "Starte Eingabe zum suchen",

    token_expired: "Ihre Push-Tan ist abgelaufen. Bitte melden Sie sich erneut an!",

    prv_pem_invalid: "َDer Schlüssel ist ungültig. Bitte eine gültige .pem-Datei hochladen.",

    male: 'Männlich',
    female: 'Weiblich',
    other_gender: 'Diverse',
    copied: 'Kopiert!',

    features: 'Merkmale',

    add_note_to_checklist: 'Notiz hinzufügen',
    note: 'Notiz',

    personaldocs: 'Persönliche Unterlagen',
    documenttemps: 'Dokumentvorlagen',
    documenttemps_subtitle: 'Laden Sie hier verschiedene Dokumentvorlagen herunter.<br>Bitte beachten Sie dass Sie für die ausfüllbaren Formulare den Adobe Acrobat Reader benötigen.',

    personaldocs_title: 'Persönliche Unterlagen',
    personaldocs_subtitle: 'Laden Sie hier Ihre persönlichen Unterlagen aller Art hoch.',

    advisor: 'alleato',
    advisor_title: 'alleato',
    advisor_subtitle: 'Wir sind kein Makler. Unser exklusiver Partner, die alleato assekuranzmakler GmbH, bietet dank großer Rahmenverträge exklusive Preise. Vergleichen Sie sie gerne mit anderen Vergleichsportalen im Internet. Klicken Sie auf das entsprechende Logo, um direkt zur Partnerseite zu gelangen – keine Sorge, dadurch entstehen keine Kosten!',
    advisorpartners_list: {
        continentale: {
            name: 'Continentale Versicherungsverbund',
        }, ideal: {
            name: 'IDEAL Versicherung AG',
        },
    },

    benefit_partners: 'Vorteilspartner',
    benefit_partners_title: 'Vorteilspartner',
    benefit_partners_subtitle: 'Entdecken Sie hier unsere Partner, bei denen Sie von erstklassigen Konditionen profitieren können.',
    benefit_partners_no_coupon: "Kein Code notwendig",
    go_to_benefit_partner: "Jetzt zum Vorteilspartner",
    coupon_code_copied: "Gutscheincode kopiert!",
    benefit_partners_list: {
        juradirekt: {
            name: 'JURA DIREKT GmbH',
            description: 'Beste Preise. Beste Leistungen. Beste Beratung.',
            long_description: 'Bei JuraDirekt profitieren Sie von unschlagbaren Preisen, herausragenden Leistungen und individueller Beratung. Unser erfahrenes Team steht Ihnen jederzeit zur Verfügung, um maßgeschneiderte Lösungen für Ihre juristischen Anliegen zu finden.'
        }, saveid: {
            name: 'Safe-iD.eu',
            description: 'Erhalten Sie 20% Preisvorteil auf alle Produkte.',
            long_description: 'Nutzen Sie jetzt Ihren exklusiven Preisvorteil von 20 % auf unser gesamtes Sortiment! Entdecken Sie unsere personalisierten SOS-Armbänder und iD-Tags, die für zusätzlichen Schutz und Sicherheit sorgen.'
        }, allvest: {
            name: 'Allvest GmbH',
            description: 'Sondertarif: 0 % Einzahlungen, 0,7 % laufende Kosten (statt 2 % und 0,6 %).',
            long_description: 'Anstatt der regulären 2 % Kosten auf Einzahlungen und laufenden Kosten von 0,6 % pro Jahr, sieht der Sondertarif 0 % auf alle Einzahlungen, bei laufenden Kosten von 0,7 % pro Jahr vor.',
        }, life4style: {
            name: 'Boheme Boutiqe Events UG (haftungsbeschränkt)',
            description: 'Erhalten Sie 25 % Preisvorteil auf alle Produkte.',
            long_description: 'Sichern Sie sich 25 % Rabatt auf alle Home and Living Produkte. Gestalten Sie Ihr Zuhause mit stilvollen Möbeln, Deko und Accessoires neu – jetzt günstiger!'
        }, dsf: {
            name: 'DeinSportsfreund.de',
            description: '15 % auf Alles außer Vereinskollektionen und Sonderaktionen.',
            long_description: 'Erhalten Sie jetzt auf DeinSportsfreund.de einen sportlichen Mehrwert von 15 % auf das gesamte Sortiment! Im Angebot haben wir Sportbekleidung, Sportschuhe und Sportzubehör der Top Marken wie adidas, Nike, Puma & Hummel uvm. Ausgeschlossen sind Vereinskollektionen unserer Vereinspartner als auch Sonderaktionen.',
        }, syncro24: {
            name: 'Syncro24 – assekuradeur GmbH',
            description: 'Beste Preise & unschlagbare Rabatte auf alle angebotenen Versicherungen.',
            long_description: 'Profitieren Sie durch die Zusammenarbeit von alleato mit Syncro24 von Top-Preisen und attraktiven Rabatten auf sämtliche angebotenen Versicherungen. Mit Syncro24 erhältst du exklusive Versicherungsprodukte, die speziell auf deine Bedürfnisse zugeschnitten sind. Wichtig: Geben Sie im Formular als Vermittler „alleato“ mit der Vermittlernummer „47055“ an, um von den Vorteilen zu profitieren.',
        }, hotels: {
            name: 'Partnerhotels', description: 'In Kürze verfügbar.'
        }, carclub: {
            name: 'Automobilclub', description: 'In Kürze verfügbar.'
        }, toppartner: {
            name: 'Top Partner', description: 'In Kürze verfügbar.'
        }, sportclub: {
            name: 'SportClub', description: 'In Kürze verfügbar.'
        },
    },

    subscriptions: 'Preismodell-Abonnement',
    subscriptions_subtitle: 'Hier finden Sie alle Nutzer für Ihr gewähltes Preismodell',

    empty_duplications: 'Ohne Abonnementbenutzer',
    expired_at: 'Abgelaufen am',

    otp_title: 'Zwei-Faktor-Authentifizierung',
    otp_setup: 'Geben Sie den 6-stelligen Sicherheitscode aus Ihrer Authentifizierungs-App ein (z.B. Google Authenticator)',
    otp_code: 'Sicherheitscode',
    success_otp_enabled: 'Zweistufige Anmeldung mit pushTAN aktiviert',
    success_otp_disabled: 'Zweistufige Anmeldung mit pushTAN deaktiviert',

    init_security_keys: 'Erstkonfiguration von Verschlüsselungen',

    add_user: 'Vertrauenspersonen hinzufügen',

    your_pays: 'Ihre Zahlungen',
    your_pays_desc: 'Hier finden Sie den Zahlungsbericht',
    empty_your_pays: 'Sie haben derzeit keine registrierten Zahlungen',

    payment_title: 'Bestelldetails',
    payment_plan_price: 'Preis',
    payment_amount: 'Zahlungsbetrag',
    paymeny_go_bank: 'Zahlen Sie mit PayPal',
    payment_plan: 'Ausgewählter Plan',

    ref: 'Ref.',

    started: 'Warten auf Zahlung',

    send_contact_email_success: 'Vielen Dank für Deine Nachricht',

    not_activated: "Nicht aktiviert",
    activated: "Aktiv",
    your_abos: "Ihre Mitgliedschaft",
    your_abos_subtitle: "Verwalten Sie hier Ihre Mitgliedschaft.",
    your_abos_list: "Gebuchte Mitgliedschaft",
    no_abos: "Noch keine gebuchte Mitgliedschaft vorhanden. Bitte wählen Sie eine Mitgliedschaft oder geben Sie einen bereits erhalten Code ein.",
    abonnement: 'Mitgliedschaft',

    last_wish_title: 'Der letzte Wunsch',
    last_wish_desc: 'Hinterlassen Sie hier Ihren letzten Wunsch',

    no_files_uploaded: 'Bisher wurden noch keine Dateien hochgeladen.',

    meta_tags: {
        home: {
            title: 'Geras24 - Der digitale Ort zur Absicherung deines Lebens und der Familie',
            description: 'Dein digitaler Begleiter zur sicheren Verwaltung und Absicherung deines Lebens und deiner Familie. Einfach und zuverlässig alles Wichtige an einem Ort verwalten.'
        }, products: {
            'der-letzte-wunsch': {
                title: 'Der letzte Wunsch - Geras24', description: 'Der letzte Wunsch'
            }, 'persoenliche-botschaft': {
                title: 'Persönliche Botschaft - Geras24', description: 'Persönliche Botschaft'
            }, checkliste: {
                title: 'Checkliste - Geras24', description: 'Checkliste'
            }, tresor: {
                title: 'Digitale Tresor - Geras24', description: 'Tresor'
            },
        }, impressum: {
            title: 'Impressum - Geras24', description: 'Impressum'
        }, datenschutz: {
            title: 'Datenschutz - Geras24', description: 'Datenschutz'
        }, seller_terms: {
            title: 'Allgemeine Verkaufsbedingungen - Geras24', description: 'Allgemeine Verkaufsbedingungen'
        }, about: {
            title: 'Über uns - Geras24', description: 'Über uns'
        }, contact_us: {
            title: 'Kontakt - Geras24', description: 'Kontakt'
        }, faq: {
            title: 'Hilfe - Geras24', description: 'Hilfe'
        }, notfallbox: {
            title: 'Notfallbox - Geras24', description: 'Geras24 Notfallbox'
        }, dashboard: {
            title: 'Dashboard - Geras24',
            support: {
                title: 'Support - Geras24',
            }, checklist: {
                title: 'Checkliste - Geras24',
            }, tresor: {
                title: 'Tresor - Geras24',
            }, profile: {
                title: 'Profil - Geras24',
            }, trusted_users: {
                title: 'Vertrauenspersonen - Geras24',
            }, personal_messages: {
                title: 'Persönliche Botschaften - Geras24',
            }, personal_documents: {
                title: 'Persönliche Unterlagen - Geras24',
            }, advisor: {
                title: 'alleato - Geras24',
            }, benefit_partners: {
                title: 'Vorteilspartner - Geras24',
            }, last_wish: {
                title: 'Der letzte Wunsch - Geras24',
            }, document_templates: {
                title: 'Dokumentvorlagen - Geras24',
            }
        }
    },

    texts: {
        home: {
            title: 'Der digitale Ort zur Absicherung deines Lebens und der Familie', register_now_for_free: 'Jetzt kostenlos registrieren', reasons: {
                reason1: 'Innovative digitale Plattform für umfassende Familienabsicherung.',
                reason2: 'Lebenslange Begleitung von der Vorsorge bis zur Nachsorge – Wir sind für dich und deine Lieben da.',
                reason3: 'Wir kümmern uns nicht nur um dich, sondern auch um deine Familie und engsten Freunde.',
            }, usage_points: {
                title: 'Warum du Geras24 nutzen solltest', subtitle: 'Unabhängige Statistiken zeigen, warum Geras24 die beste Wahl für dich und deine Familie ist.', default_button_text: 'Sicherheit im ...', point1: {
                    button_text: 'Brandfall',
                    title: 'Sicherheit im Brandfall',
                    description: 'Jedes Jahr verlieren Menschen in Deutschland und auf der ganzen Welt durch Wohnungsbrände wertvolle Erinnerungen und wichtige Unterlagen. Videos aus der Kindheit, Familienfotos oder persönliche Andenken, die oft von unschätzbarem emotionalem Wert sind, werden unwiederbringlich zerstört. Doch neben diesen emotionalen Verlusten können auch gravierende praktische Probleme entstehen, wenn wichtige Dokumente, wie notarielle Unterlagen oder Verträge, in den Flammen verloren gehen. Der Verlust solcher Dokumente könnte beispielsweise zu rechtlichen und finanziellen Schwierigkeiten führen, wenn Nachweise über Eigentum oder Vereinbarungen fehlen. Diese Beispiele verdeutlichen, wie wichtig es ist, essentielle Unterlagen sicher aufzubewahren.'
                }, point2: {
                    button_text: 'Hochwasserfall',
                    title: 'Sicherheit im Hochwasserfall',
                    description: 'Jedes Jahr sind Menschen in Deutschland und weltweit von den verheerenden Folgen von Hochwasser betroffen. Der Klimawandel führt zu immer häufigeren und intensiveren Starkregenereignissen, wodurch das Risiko für Überschwemmungen wächst. Diese Naturkatastrophen können nicht nur Gebäude und Infrastruktur schwer beschädigen, sondern auch wertvolle persönliche Besitztümer und wichtige Dokumente gefährden. Beispielsweise können wichtige Unterlagen wie medizinische Befunde oder Immobilienpapiere durch das Wasser unleserlich oder unbrauchbar werden. Dies kann zu Schwierigkeiten bei der Wiederbeschaffung von Dokumenten oder der Klärung von Ansprüchen führen. Angesichts der zunehmenden Gefahr von Hochwasser ist es daher entscheidend, Maßnahmen zum Schutz und zur sicheren Aufbewahrung wichtiger Unterlagen zu ergreifen.'
                }, point3: {
                    button_text: 'Notfall',
                    title: 'Sicherheit im Notfall',
                    description: 'Die meisten Unfälle passieren im eigenen Zuhause - Tendenz steigend, weshalb es entscheidend ist, dass die medizinische Vorsorge gut organisiert ist. Patientenverfügungen, medizinische Anweisungen und Versicherungsunterlagen sollten an einem sicheren Ort aufbewahrt werden. Viele Menschen denken selten an den Notfall und haben keine klaren Anweisungen hinterlegt. Dies kann für Angehörige äußerst belastend sein, wenn sie im Ernstfall nicht wissen, ob solche Dokumente vorhanden sind oder wo sie zu finden sind. Eine gute Organisation dieser wichtigen Unterlagen kann nicht nur schnelle Entscheidungen erleichtern, sondern auch dazu beitragen, die Belastung für die Familie zu minimieren.'
                }
            }, customer_feedback: {
                title: 'Was unsere Kunden über uns denken', subtitle: 'Wir sind stolz auf unsere Zahlen und die Zufriedenheit unserer Kunden.',
            }, statistics: {
                title: 'Warum du auf Geras24 vertrauen kannst',
                subtitle: 'Die Geras24-Community wächst stetig und rettet somit aktiv Leben.',
                statistic_members: 'Aktive Mitglieder',
                statistic_emergency_folders: 'Verwendete Notfallordner',
                statistic_emergency_cards: 'Verwendete Notfallkarten'
            }, pricing: {
                title: 'Unsere Preise',
                subtitle: 'Im Folgenden findest du unsere verschiedenen Modelle, um Mitglied zu werden. Sichere dir jetzt ein Stück Sicherheit für dein Leben.',
                register_book_now: 'Jetzt buchen',
                mostly_chosen: 'Am meisten gewählt',
                yes: 'Ja',
                plans: {
                    family_carefree: {
                        title: 'Family Sorglos', subtitle: 'Volle Kostenkontrolle mit maximalem Umfang.'
                    }, lifetime: {
                        title: 'Ein Leben Lang Sorglos', subtitle: 'Von Anfang bis Ende die beste Entscheidung.'
                    }
                },
                periods: {
                    monthly: 'monatlich', yearly: 'jährlich', lifetime: 'einmalig'
                },
                features: {
                    seven_days_trial: '7 Tage kostenlose Testphase',
                    storage: 'Speicherplatz',
                    checklist: 'Checkliste',
                    last_wish: 'Der letzte Wunsch',
                    personal_message: 'Persönliche Botschaft',
                    personal_documents: 'Persönliche Unterlagen',
                    document_templates: 'Dokumentvorlagen',
                    vault: 'Digitale Tresor',
                    up: 'Up',
                }
            }, security: {
                dsgvo: 'DSGVO', data_store: 'Datenspeicher in Deutschland', ssl: 'SSL-Verschlüsselung'
            }, in_cooperation_with: 'In Zusammenarbeit mit', features: {
                personal_message: {
                    title: 'Persönliche Botschaft', description: 'Verfasse und hinterlege persönliche Botschaften für deine Liebsten.'
                }, checklist: {
                    title: 'Checkliste', description: 'Mit unserer Checkliste behältst du im Todesfall den Überblick.'
                }, vault: {
                    title: 'Digitale Tresor', description: 'Lade wichtige Dokumente hoch und erhalte alle Unterlagen von unseren Partnern.'
                }, last_wish: {
                    title: 'Der letzte Wunsch', description: 'Halte deinen letzten Wunsch so detailliert wie möglich fest.'
                }, up: {
                    title: 'Up', description: 'Gestalte bereits jetzt dein Leben nach dem Tod im virtuellen Himmel von Geras24.'
                }, insurance: {
                    title: 'Persönliche Absicherung', description: 'Unschlagbare Versicherungen mit Bestleistungen von unserem Partner alleato.'
                }
            }, reviews: {
                loading_error: 'Die Bewertungen konnten nicht geladen werden.',
            }
        }, products: {
            checklist: {
                intro_title: 'Die Checkliste nach einem Todesfall',
                intro_description: 'Stell dir vor, ein geliebter Mensch ist plötzlich verstorben und du stehst als Angehöriger vor der Aufgabe, alle Angelegenheiten zu regeln. Doch keine Sorge, wir haben eine kostenlose Checkliste für dich erstellt, die dir Schritt für Schritt zeigt, was nach einem Todesfall zu erledigen ist. Von der Kündigung von Verträgen bis hin zur Auszahlung von Lebensversicherungen - wir haben alles im Blick. Als Mitglied bei Geras24 kannst du dich darauf verlassen, dass wir dir diese mühsame Arbeit abnehmen. Denn mit uns kannst du bereits zu Lebzeiten alles Wichtige regeln. Lass uns dir helfen und werde noch heute Mitglied!',
            }, last_wish: {
                intro_title: 'Der letzte Wunsch',
                intro_description: 'Jeder hat für die Zeit nach seinem Tod eine andere Vorstellung davon, was er als richtig und schön empfindet. Viele können sich eine klassische Beerdigung nicht vorstellen. Sie wollen keine trauernden Gäste, sondern Freunde und liebe Menschen um sich wissen, die an die schönen Zeiten denken. Nur du bist in der Lage, schon zu Lebzeiten zu bestimmen, was dir wichtig ist. Halte deinen letzten Wunsch so detailliert wie möglich fest und hinterlege ihn auf der Plattform von Geras24. So erleichterst du deinen Liebsten die ohnehin schwere Zeit nach deinem Tod. Werde noch heute ein Mitglied der Geras24 Gemeinschaft und halte fest, wie dein letzter Wunsch genau aussieht.',
            }, personal_message: {
                intro_title: 'Persönliche Botschaft',
                intro_description: 'Eine ganz besondere Funktion bei Geras24 ist die persönliche Botschaft. Hier hast du die Möglichkeit, deinen Liebsten Videos, Sprachnachrichten oder Briefe zukommen zu lassen. So bleibst du ihnen auch über den Tod hinaus erhalten und schaffst wertvolle Momente.',
            }, digital_safe: {
                intro_title: 'Der digitale Tresor',
                intro_description: 'Eines der Hauptprodukte von Geras24 ist die Möglichkeit, alle wichtigen Dokumente sicher hochzuladen. Hier findest du alles, was du für deine tägliche Buchhaltung benötigst, wie zum Beispiel Kontoauszüge, Versicherungspolicen oder Arztrechnungen. Doch nicht nur für den aktuellen Bedarf ist diese Funktion nützlich - du kannst auch alle Dokumente sicher abspeichern, um für den Fall eines schweren Unfalls oder im Todesfall sicherzustellen, dass deine Hinterbliebenen auf alle wichtigen Unterlagen zugreifen können. Registriere dich jetzt kostenlos und nutze diese wertvolle Funktion!',
            },
        }, footer: {
            personal_message: 'Persönliche Botschaft',
            checklist: 'Checkliste',
            vault: 'Digitale Tresor',
            last_wish: 'Der letzte Wunsch',
            emergency_box: 'Notfallbox',
            up: 'Up',
            help: 'Hilfe',
            contact: 'Kontakt',
            about_us: 'Über uns',
            geras24_shop: 'Geras24 Shop',
            all_rights_reserved: 'Alle Rechte vorbehalten.',
            imprint: 'Impressum',
            privacy: 'Datenschutz',
            terms: 'Allgemeine Verkaufsbedingungen',
        }
    }
}
