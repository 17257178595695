import axios from 'axios'
import Vue from 'vue'
import store from '../../index'

export default {

    async getPays({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: '/dashboard/pays', method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getPlans({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getPlansGuest({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans/guest`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getFeaturesGuest({ commit }) {
        return new Promise((resolve, reject) => {
            axios({ url: `/features/guest`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getPlanSubsRequest({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans/${data.id}/subscribe/${data.method}${data.plan_id ? `/${data.plan_id}` : ''}`, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getPaymentVerify({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/subscriptions/${data.id}/${data.status}?${data.params}`, method: 'GET' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async redeemCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans/subscribe/redeem-code`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async redeemGivenCode({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios({ url: `/plans/subscribe/redeem-given-code`, data: data, method: 'POST' })
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}