<template>
  <div id="footer-wrapper" ref="footerWrapper">
    <v-container>
      <v-footer
          padless
          color="#1E1E1E"
          class="d-flex flex-column pt-10 pb-6"
      >
        <v-row class="footer-wrapper-row pb-10">
          <v-col cols="12" sm="6" class="pa-0 mb-5 text-center text-sm-left">
            <div class="d-flex justify-center justify-sm-start">
              <img
                  src="/assets/img/logo-w.svg"
                  alt="Geras24"
                  class="mb-2"
                  :width="140"
              >
            </div>
            <div class="d-inline-flex ml-n3">
              <SocialMediaIcons icon-color="white" icon-size="25"></SocialMediaIcons>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0 pb-sm-3">
                <div class="text-center text-sm-right">
                  <a
                      v-for="(link, index) in links.left"
                      :key="index"
                      :href="link.to"
                      :target="link.target ? link.target : '_self'"
                      class="white--text d-block mb-2 text-body-2 font-weight-medium text-decoration-none"
                  >
                    {{ link.title }}
                  </a>
                </div>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pt-sm-3">
                <div class="text-center text-sm-right">
                  <a
                      v-for="(link, index) in links.right"
                      :key="index"
                      :href="link.to"
                      :target="link.target ? link.target : '_self'"
                      class="white--text d-block mb-2 text-body-2 font-weight-medium text-decoration-none"
                  >
                    {{ link.title }}
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="footer-wrapper-row">
          <v-divider color="white"></v-divider>
        </v-row>
        <v-row class="footer-wrapper-row pt-5">
          <v-col cols="12" sm="6" class="pa-0 mb-3 mb-sm-0">
            <div class="white--text text-body-2 text-center text-sm-left">
              © {{ year }} Geras24. {{ $t('texts.footer.all_rights_reserved') }}
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pa-0">
            <div class="d-flex justify-center justify-sm-end mr-n4">
              <a
                  v-for="(link, index) in links.bottom"
                  :key="index"
                  :href="link.to"
                  :target="link.target ? link.target : '_self'"
                  class="white--text text-body-2 text-decoration-none mr-4"
              >
                {{ link.title }}
              </a>
            </div>
          </v-col>
        </v-row>

        <v-btn
            v-if="!$store.getters.isLoggedIn"
            elevation="12"
            fixed
            bottom
            right
            text
            @click="showRegister = true"
            class="btn-default btn-blue"
            style="z-index: 2"
        >
          Kostenlos<br/>registrieren
        </v-btn>

      </v-footer>
    </v-container>
    <div id="footer-utils">
      <login-dialog
          :value="localShowLogin"
          @input="updateLoginModal"
          @close-login-modal="handleCloseLoginModal"
          @open-register-modal="handleOpenRegisterModal"
      ></login-dialog>
      <register-dialog
          :value="localShowRegister"
          :price-model="pricingPlan"
          @input="updateRegisterModal"
          @close-register-modal="handleCloseRegisterModal"
          @open-login-modal="handleOpenLoginModal"
      ></register-dialog>
      <cookie-banner></cookie-banner>
    </div>
  </div>
</template>

<script>
import LoginDialog from "../../widgets/LoginDialog.vue";
import RegisterDialog from "../../widgets/RegisterDialog.vue";
import CookieBanner from "../../widgets/CookieBanner.vue";
import SocialMediaIcons from "@/widgets/SocialMediaIcons.vue";

export default {
  components: {
    SocialMediaIcons,
    LoginDialog,
    RegisterDialog,
    CookieBanner
  },
  props: {
    showLogin: Boolean,
    showRegister: Boolean,
    pricingPlan: Object
  },
  data() {
    return {
      localShowLogin: this.showLogin,
      localShowRegister: this.showRegister,
      links: {
        left: [
          {title: this.$t('texts.footer.personal_message'), to: '/produkte/persoenliche-botschaft'},
          {title: this.$t('texts.footer.checklist'), to: '/produkte/checkliste'},
          {title: this.$t('texts.footer.vault'), to: '/produkte/tresor'},
          {title: this.$t('texts.footer.last_wish'), to: '/produkte/der-letzte-wunsch'},
          {title: this.$t('texts.footer.up'), to: 'https://www.up-geras24.de/', target: '_blank'},
        ],
        right: [
          {title: this.$t('texts.footer.help'), to: '/hilfe'},
          {title: this.$t('texts.footer.contact'), to: '/kontakt'},
          {title: this.$t('texts.footer.about_us'), to: '/ueber-geras24'},
          {title: this.$t('texts.footer.emergency_box'), to: '/notfallbox'},
          {title: this.$t('texts.footer.geras24_shop'), to: 'https://shop.geras24.de', target: '_blank'},
        ],
        bottom: [
          {title: this.$t('texts.footer.imprint'), to: '/impressum'},
          {title: this.$t('texts.footer.privacy'), to: '/datenschutz'},
          {title: this.$t('texts.footer.terms'), to: '/allgemeine-verkaufsbedingungen'},
        ]
      },
      year: '',
    }
  },
  watch: {
    showLogin(newVal) {
      this.localShowLogin = newVal;
    },
    showRegister(newVal) {
      this.localShowRegister = newVal;
    }
  },
  mounted() {
    this.getYear();
    this.adjustFooterMargin();
  },
  methods: {
    getYear() {
      let d = new Date();
      this.year = d.getFullYear()
    },
    adjustFooterMargin() {
      this.$nextTick(() => {
        const wrapElement = document.querySelector(".v-application--wrap > :nth-child(2)");
        const footerElement = this.$refs.footerWrapper;
        const viewportHeight = window.innerHeight;
        const wrapHeight = wrapElement ? wrapElement.offsetHeight : 0;
        const headerHeight = 90;

        footerElement.style.marginTop = "0";

        if (wrapHeight < viewportHeight - headerHeight) {
          const additionalMargin = viewportHeight - headerHeight - wrapHeight;

          footerElement.style.marginTop = `${additionalMargin}px`;
        }
      });
    },
    updateLoginModal(val) {
      this.localShowLogin = val; // Update local value
      this.$emit('input', val);  // Emit the change to the parent
    },
    updateRegisterModal(val) {
      this.localShowRegister = val; // Update local value
      this.$emit('input', val);     // Emit the change to the parent
    },
    openRegisterModal() {
      this.localShowRegister = true;   // Open register modal
      this.localShowLogin = false;     // Ensure login modal is closed
      this.$emit('open-register-modal'); // Emit to parent
    },
    handleCloseLoginModal() {
      this.localShowLogin = false;
      this.$emit('close-login-modal'); // Notify parent to close login modal
    },
    handleOpenRegisterModal() {
      this.localShowLogin = false;
      this.localShowRegister = true;
      this.$emit('open-register-modal'); // Notify parent to open register modal
    },
    handleCloseRegisterModal() {
      this.localShowRegister = false;
      this.$emit('close-register-modal'); // Notify parent to close register modal
    },
    handleOpenLoginModal() {
      this.localShowRegister = false;
      this.localShowLogin = true;
      this.$emit('open-login-modal'); // Notify parent to open login modal
    }
  }
};
</script>

<style scoped>
#footer-wrapper {
  background-color: #1E1E1E;
  color: white;
}

.footer-wrapper-row {
  width: 100%;
}

#footer-utils {
  position: absolute;
}
</style>
