import axios from 'axios'

export default {

    async adminGetStats({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/stats`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminGetSettings({commit}) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/settings`, method: 'GET'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminSetSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/settings`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUploadPhotoSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/settings/photo`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminUploadVideoSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/settings/video`, data: data, method: 'POST'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async adminDeleteSettings({commit}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `/admin/settings`, data: data, method: 'DELETE'})
                .then(resp => {
                    resolve(resp.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
}
